import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/styles";
import { FaUser } from 'react-icons/fa';
import { connect } from 'react-redux';
import moment from 'moment';
import { IoMdCall } from 'react-icons/io';
import { HiMail } from 'react-icons/hi';
import { MdOutlineMoreTime } from 'react-icons/md';
import { FaWhatsappSquare } from 'react-icons/fa';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import upload from '../../Assets/Image/upload.png'
import S3 from "react-aws-s3";
import Compressor from 'compressorjs';
import nothere from '../../Assets/Image/nope-not-here.webp';
import SendIcon from '@mui/icons-material/Send';
import Stack from "@mui/material/Stack";
import { useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import * as Action from "../../actions/adminAction"
import * as Auth from "../../actions/authActions"
import ProfilePic from "../../Assets/Image/Profile Admin.png";
import UTILS from "../../utils/uploadImage";
import { Card, Paper, Typography, Table, Button, Modal, IconButton, Box, TextField, InputAdornment, OutlinedInput, FormControl, CardContent, Avatar, Alert, InputLabel, CardActions, CardActionArea, CircularProgress } from '@mui/material';
export const Profile = ({ userDetails, updateProfilePassword, logout, UpdateUserSignature, updateProfilePic }) => {

  const navigate = useNavigate();
  const classes = useStyles()
  //Password Variables
  const [loader, setLoader] = useState(false)
  const [updatePass, setUpdatePass] = useState(false)
  const [newPass, setNewPass] = useState('')
  const [oldPass, setOldPass] = useState('')
  const [newPassV, setnewPassV] = useState(false)
  const [oldPassV, setoldPassV] = useState(false)
  const [error, setError] = useState(false)
  const [oldPassError, setoldPassError] = useState(false)
  const [Success, setSuccess] = useState(false)
  const [signature, setsignature] = useState()

  const handlePass = async () => {
    setLoader(true)
    setError(false)
    setoldPassError(false)
    if (oldPass === '' || newPass === '') {
      setError(true)
      setLoader(false)
    }
    else {
      const result = await updateProfilePassword({
        newPassword: newPass,
        oldPassword: oldPass,
        user_id: userDetails?._id
      })
      if (result?.msg !== "Not Matched") {
        // console.log(result)
        setUpdatePass(false)
        setError(false)
        setLoader(false)
        setSuccess(true)
      } else {
        setoldPassError(true)
        setLoader(false)
      }
    }
  }

  const openPass = () => {
    setOldPass('');
    setNewPass('');
    setUpdatePass(true);
  }

  const closeAndLogout = async () => {
    const res = await logout();
    navigate('/');
    setUpdatePass(false);
  }

  const uploadImageToS3 = (event, type) => {
    let fileInput = false;
    const image = event;
    let imageName = event.name;
    if (event) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.9,
        success: async(compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = "tms/profileimages/"+getTimeStamp + "_"+imageName;
          let imageUrl = await UTILS.uploadImageFromBackend(newFileName, image.type, compressedResult);
          if (imageUrl) {
            if (type === 'Signature') {
              setsignature(imageUrl)
              UpdateUserSignature({
                user_id: userDetails?._id,
                signature: imageUrl
              })
            } else if (type === 'Profile') {
              updateProfilePic({
                profilePhotoURL: imageUrl,
                user_id: userDetails?._id
              })
            }

          } else {
            console.log("AWS Error")
          }
          
        },
      });
    }
  };

  const handleChange = async (e, IMGType) => {
    if (e.target.files.length) {
      const img = URL.createObjectURL(e.target.files[0]);
      // console.log("Files Type", e.target.files[0]);
      if (e.target.files[0].type.split('/')[0] === 'image') {
        uploadImageToS3(e.target.files[0], IMGType);
        // console.log("Its valid Image")
      } else {
        // console.log("Something Else")
      }
    }
  };

  return (
    <div className={classes.main}>
      <Typography
        variant="h3"
        sx={{ marginTop: 7, fontWeight: 600 }}
        component="h2"
      >
        Profile
      </Typography>
      <div style={{ margin: 20 }}>
        <Card style={{ width: "600px" }}>
          <center>
            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={(e) => handleChange(e, "Profile")} style={{ display: "none" }} />
            <label htmlFor='icon-button-file'>
              <IconButton color="primary" aria-label="upload picture" component="span">
                <img src={upload} className={classes.image} />
                <Avatar src={userDetails?.profilePicURL ? userDetails?.profilePicURL : ProfilePic} className={classes.profilePicture} />
              </IconButton>
            </label>
          </center>
          <CardContent>
            <Typography variant="h5" component="div" className={classes.middlePosition}>
              {userDetails.firstName} {userDetails.lastName}
            </Typography>

            <Table style={{ fontSize: 25, marginTop: 30 }} >
              <tbody >

                <tr>
                  <td> <IoMdCall style={{ width: 30, height: 30, color: '#1a799e' }} /></td>
                  <td >{userDetails.contactNo}</td>

                  <td><MdOutlineMoreTime style={{ width: 30, height: 30, color: '#1a799e' }} /> </td>
                  <td >{moment(userDetails.createdAt).format("DD MMM YYYY")}</td>
                </tr>


                <tr >
                  <td ><HiMail style={{ width: 30, height: 30, color: '#1a799e' }} /></td>
                  <td >{userDetails.emailId}</td>


                  <td ><FaWhatsappSquare style={{ width: 30, height: 30, color: 'green' }} /></td>
                  <td >{userDetails.whatsappNo}</td>
                </tr>

                <tr>

                </tr>


              </tbody></Table>

          </CardContent>
          <CardContent>
            {userDetails?.userType[0] === "trainer" ?
              <div style={{ width: '100%' }}>
                <a target="_blank" href={userDetails?.trainerSignature ? userDetails?.trainerSignature : null} >
                  <img
                    src={userDetails?.trainerSignature ? userDetails?.trainerSignature : nothere}
                    style={{
                      width: 160,
                      height: 80,
                      border: '1px solid black'
                    }}
                  />
                </a>
                <input type="file" style={{ display: 'none' }} accept="image/*" id="MySigntaure" onChange={(e) => {
                  handleChange(e, "Signature"); // console.log("HIIII")
                }} />
                <label htmlFor='MySigntaure'>
                  <IconButton
                    color='primary' aria-label="upload picture" component="span"
                    style={{ margin: 10, borderRadius: '10%', color: 'white', backgroundColor: '#1d5a96', fontSize: 17, fontWeight: 400 }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f6cc63 !important",
                        fontSize: 17,
                      },
                    }}
                  >
                    Upload Signature
                  </IconButton>
                </label>
              </div> : null}
          </CardContent>
        </Card>
        <center>
          <Button variant='contained' style={{ margin: 10 }} onClick={() => openPass()}>Update Password</Button>
        </center>
      </div>

      {/* Update Password Modal */}
      <Modal
        className={classes.middlePosition}
        open={updatePass}
        onClose={e => {
          e.preventDefault()
          setUpdatePass(false)
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Change Password</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setUpdatePass(false)
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box className={classes.middlePassword}>
            {/* <InputLabel id="outlined-adornment-Bank">Old Password</InputLabel> */}
            <TextField
              label="Old Password"
              // id="outlined-adornment-Bank"
              className={classes.input}
              style={{ margin: 10 }}
              // placeholder='Old Password'
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
              type={oldPassV ? "text" : "Password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setoldPassV(!oldPassV)}>
                      {oldPassV ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box className={classes.middlePassword}>
            <TextField
              label="New Password"
              className={classes.input}
              style={{ margin: 10 }}
              // placeholder='New Password'
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              type={newPassV ? "text" : "Password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setnewPassV(!newPassV)}>
                      {newPassV ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <span style={{ margin: 5, fontSize: 20, color: 'red' }}>{error ? "*Password cannot be Empty" : null}</span>
          <span style={{ margin: 5, fontSize: 20, color: 'red' }}>{oldPassError ? "*Old Password does not match" : null}</span>
          <center>
            <Button style={{ marginTop: 10, fontSize: 16 }} variant="contained" onClick={() => handlePass()} size="medium" >
              {loader ? <CircularProgress style={{ color: 'white' }} /> : 'Submit'}{loader ? null : <SendIcon style={{ marginLeft: 5 }} />}
            </Button>
          </center>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={Success}
        onClose={e => {
          e.preventDefault()
          setSuccess(false)
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Password Updated Successfully !!!</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setSuccess(false)
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <center>
            <Button variant='contained' onClick={() => closeAndLogout()} >Ok</Button>
          </center>
        </Paper>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column'
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '40px',
    // padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '900px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  companyProfile: {
    width: '1400px',
    background: 'white',
    height: '630px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 80
  },
  middlePassword: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  updateImage: {
    width: 150,
    height: 120,
    marginRight: 30,
    border: '1px solid black'
  }, input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
  profilePicture: {
    width: '150px !important',
    height: '150px !important',
    margin: '20px',
  }, image: {
    position: "absolute",
    top: 65,
    bottom: 85,
    left: 70,
    right: 85,
    height: 70,
    width: 70,
    opacity: 0.6,
    borderRadius: 20,
    backgroundColor: 'red',
    zIndex: 1000
  },
}));


const mapStateToProps = (state) => {
  return {

    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfilePassword: (data) => dispatch(Action.updateProfilePassword(data)),
    UpdateUserSignature: (data) => dispatch(Action.UpdateUserSignature(data)),
    updateProfilePic: (data) => dispatch(Action.updateProfilePic(data)),
    logout: () => dispatch(Auth.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)